<template>
  <footer class="footer">
    <div class="footer-1 grid sm:grid-cols-2 md:grid-cols-4 gap-4">
      <div>
        <section>
          <h4 class="footer-title">SMS Brochure</h4>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/SMSBrochure.pdf?alt=media&token=aca4ffb3-e5be-4077-89b2-d97cb133e79b"
          >
            <img
              width="200"
              height="155"
              src="@/assets/brochuresmall-min.jpg"
              class="image wp-image-12328  attachment-full size-full"
              alt=""
              style="max-width: 100%; height: auto;"
          /></a>

          <div style="clear:both;"></div>
        </section>
      </div>
      <div>
        <section>
          <h4 class="footer-title">SMS Training Brochure</h4>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/smsenergetics.appspot.com/o/2023-SMS-TCI-Training-Brochure.pdf?alt=media&token=e96142f6-e881-4a4e-a7d7-c7de5fd9bc78"
          >
            <img
              width="200"
              height="155"
              src="@/assets/trainingbrochuresmall-200x155-min.jpg"
              class="image wp-image-12872  attachment-fusion-200 size-fusion-200"
              alt=""
              style="max-width: 100%; height: auto;"
              title="SMS Training Brochure"
          /></a>

          <div style="clear:both;"></div>
        </section>
      </div>
      <div></div>
      <div>
        <section class="contact_info">
          <h4 class="footer-title">SMS Contact Information</h4>
          <div class="contact-info-container">
            <p class="address">
              1847 West 9000 South, Suite 205<br />West Jordan, UT 84088
            </p>
            <p class="phone">Phone: 801-567-0456</p>
            <p class="fax">Fax: 801-567-0457</p>
            <p class="web">
              Web:
              <router-link style="color:#bfbfbf;" to="/contact">
                smsenergetics.com
              </router-link>
            </p>
            <p>
              Group:
              <a
                href="https://etusersgroup.org"
                target="_blank"
                style="color:#bfbfbf;"
              >
                etusersgroup.org
              </a>
            </p>
            <p>
              Software:
              <a
                href="https://godetect.smsenergetics.com"
                target="_blank"
                style="color:#bfbfbf;"
              >
                godetect;
              </a>
              <a
                href="https://ivm13.com"
                target="_blank"
                style="color:#bfbfbf;"
              >
                IVM
              </a>
            </p>
          </div>
          <div style="clear:both;"></div>
        </section>
      </div>
    </div>
    <footer class="footer-bottom">
      <div class="fusion-row">
        <div class="fusion-copyright-content">
          <div class="fusion-social-links-footer">
            <div class="fusion-social-networks">
              <div class="fusion-social-networks-wrapper">
                <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4">
                  <div>
                    <div class="fusion-copyright-notice">
                      <div>
                        Copyright 2012 - 2021 Safety Management Services, Inc. |
                        All Rights Reserved
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display:inline-block;">
                      <a
                        class="fusion-social-network-icon fusion-tooltip fusion-facebook fusion-icon-facebook"
                        style=""
                        href="https://www.facebook.com/safetymanagementservices"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-placement="top"
                        data-title="facebook"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="facebook"
                      >
                        <svg
                          style="width:20px;fill:#46494a;"
                          viewBox="0 0 50 50"
                          class="icon icons8-Facebook-Filled"
                        >
                          <path
                            d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z"
                          ></path>
                        </svg>
                        <span class="screen-reader-text">facebook</span>
                      </a>
                    </div>
                    <div style="display:inline-block;margin-left:8px;">
                      <a
                        class="fusion-social-network-icon fusion-tooltip fusion-linkedin fusion-icon-linkedin"
                        style=""
                        href="https://www.linkedin.com/company/safety-management-services-inc/"
                        target="_blank"
                        rel="noopener noreferrer"
                        data-placement="top"
                        data-title="linkedin"
                        data-toggle="tooltip"
                        title=""
                        data-original-title="linkedin"
                      >
                        <svg style="width:20px;" viewBox="0 50 512 512">
                          <path
                            fill="#46494a"
                            d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
                                                C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z
                                                M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615
                                                c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
                                                s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"
                          />
                        </svg>
                        <span class="screen-reader-text">linkedin</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- fusion-fusion-copyright-content -->
      </div>
      <!-- fusion-row -->
    </footer>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String
  },
  data: function() {
    return {};
  }
};
</script>

<style lang="scss" src="./Footer.scss"></style>
