<template>
  <div class="home">
    <div class="carousel relative shadow-2xl bg-white">
      <div class="carousel-inner relative overflow-hidden w-full">
        <!--Slide 1-->
        <input
          class="carousel-open"
          type="radio"
          id="carousel-1"
          name="carousel"
          aria-hidden="true"
          hidden=""
          checked="checked"
        />
        <div class="carousel-item absolute opacity-0">
          <div class="block h-full w-full text-white text-5xl text-center">
            <img
              src="@/assets/riskmanagement-slide.min.jpg"
              alt="risk management services"
            />
          </div>
          <router-link
            to="/risk-management"
            class="carousel-link bg-gray-300 hover:bg-gray-800 text-white font-bold py-2 px-4 inline-flex items-center"
          >
            <svg
              style="width:20px;margin-right:10px;fill:white"
              viewBox="0 0 32 32"
              class="icon icon-caret-right"
              aria-hidden="true"
            >
              <path d="M11.303 8l11.394 7.997L11.303 24z" />
            </svg>
            <span>Risk Management Services</span>
          </router-link>
        </div>
        <label
          for="carousel-3"
          class="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-2"
          class="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 2-->
        <input
          class="carousel-open"
          type="radio"
          id="carousel-2"
          name="carousel"
          aria-hidden="true"
          hidden=""
        />
        <div class="carousel-item absolute opacity-0">
          <div class="block h-full w-full text-white text-5xl text-center">
            <img
              src="@/assets/disposalexplosion-min.jpg"
              alt="explosive tesing services"
            />
          </div>
          <router-link
            to="/testing"
            class="carousel-link text-base bg-gray-300 hover:bg-gray-800 text-white font-bold py-2 px-4 inline-flex items-center"
          >
            <svg
              style="width:20px;margin-right:10px;fill:white"
              viewBox="0 0 32 32"
              class="icon icon-caret-right"
              aria-hidden="true"
            >
              <path d="M11.303 8l11.394 7.997L11.303 24z" />
            </svg>
            <span>Energetic Testing Services</span>
          </router-link>
        </div>
        <label
          for="carousel-1"
          class="prev control-2 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-3"
          class="next control-2 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!--Slide 3-->
        <input
          class="carousel-open"
          type="radio"
          id="carousel-3"
          name="carousel"
          aria-hidden="true"
          hidden=""
        />
        <div class="carousel-item absolute opacity-0">
          <div class="block h-full w-full text-white text-5xl text-center">
            <img
              src="@/assets/homeslide3-min.jpg"
              alt="energetic test equipment"
            />
          </div>
          <router-link
            to="/test-equipment"
            class="carousel-link bg-gray-300 hover:bg-gray-800 text-white font-bold py-2 px-4 inline-flex items-center"
          >
            <svg
              style="width:20px;margin-right:10px;fill:white"
              viewBox="0 0 32 32"
              class="icon icon-caret-right"
              aria-hidden="true"
            >
              <path d="M11.303 8l11.394 7.997L11.303 24z" />
            </svg>
            <span>Energetic Test Equipment</span>
          </router-link>
        </div>
        <label
          for="carousel-2"
          class="prev control-3 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-1"
          class="next control-3 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        >

        <!-- Slide 4
        <input
          class="carousel-open"
          type="radio"
          id="carousel-4"
          name="carousel"
          aria-hidden="true"
          hidden=""
        />
        <div class="carousel-item absolute opacity-0">
          <div class="block h-full w-full text-white text-5xl text-center">
            <img src="@/assets/homeslide4-min.jpg" alt="resources" />
          </div>
          <router-link
            to="/test-equipment"
            class="carousel-link bg-gray-300 hover:bg-gray-400 text-white font-bold py-2 px-4 inline-flex items-center"
          >
            <svg
              style="width:20px;margin-right:10px;fill:white"
              viewBox="0 0 32 32"
              class="icon icon-caret-right"
              aria-hidden="true"
            >
              <path d="M11.303 8l11.394 7.997L11.303 24z" />
            </svg>
            <span>Test Equipment</span>
          </router-link>
        </div>
        <label
          for="carousel-3"
          class="prev control-4 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 left-0 my-auto"
          >‹</label
        >
        <label
          for="carousel-1"
          class="next control-4 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-blue-700 leading-tight text-center z-10 inset-y-0 right-0 my-auto"
          >›</label
        > -->

        <!-- Add additional indicators for each slide-->
        <ol class="carousel-indicators">
          <li class="inline-block mr-3">
            <label
              for="carousel-1"
              class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >•</label
            >
          </li>
          <li class="inline-block mr-3">
            <label
              for="carousel-2"
              class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >•</label
            >
          </li>
          <li class="inline-block mr-3">
            <label
              for="carousel-3"
              class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >•</label
            >
          </li>
          <!-- <li class="inline-block mr-3">
            <label
              for="carousel-4"
              class="carousel-bullet cursor-pointer block text-4xl text-white hover:text-blue-700"
              >•</label
            >
          </li> -->
        </ol>
      </div>
    </div>

    <div class="px-8 py-10 container" style="max-width: 1150px;margin:0 auto;">
      <div class="sms-title">
        <h2 class="title-heading">
          Why Does Your Organization Need Safety Management and Process Hazards
          Analysis?
        </h2>
        <div class="title-sep-container">
          <div class="title-sep sep-double sep-solid"></div>
        </div>
      </div>

      <ul class="sms-checklist">
        <li class="li-item">
          <span
            style="background-color:#aa212c;font-size:11.44px;height:22.1px;width:22.1px;margin-right:9.1px;"
            class="icon-wrapper circle-yes"
          >
            <svg
              style="width: 12px;margin-left: 5px;margin-top: 5px;"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
              ></path>
            </svg>
          </span>
          <div class="li-item-content" style="margin-left:31.2px;">
            Your company’s very existence may be challenged by a single serious
            industrial accident. Personnel injury or death, damage, claims,
            downtime, lost capacity, lost opportunities, legal fees, and
            government penalties can each exact heavy tolls on corporate
            resources.
          </div>
        </li>
        <li class="li-item">
          <span
            style="background-color:#aa212c;font-size:11.44px;height:22.1px;width:22.1px;margin-right:9.1px;"
            class="icon-wrapper circle-yes"
          >
            <svg
              style="width: 12px;margin-left: 5px;margin-top: 5px;"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
              ></path>
            </svg>
          </span>
          <div class="li-item-content" style="margin-left:31.2px;">
            Smaller incidents can erode your competitive position and profits.
          </div>
        </li>
        <li class="li-item">
          <span
            style="background-color:#aa212c;font-size:11.44px;height:22.1px;width:22.1px;margin-right:9.1px;"
            class="icon-wrapper circle-yes"
          >
            <svg
              style="width: 12px;margin-left: 5px;margin-top: 5px;"
              aria-hidden="true"
              focusable="false"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
              ></path>
            </svg>
          </span>
          <div class="li-item-content" style="margin-left:31.2px;">
            Possible criminal liabilities exist for managers and directors who
            are negligent in safeguarding employees, the community, and the
            environment from hazardous processes.
          </div>
        </li>
      </ul>

      <h2 class="text-left my-5">
        Safety Management Services, Inc. (SMS™) is prepared to assist you in
        protecting both your personal and business investments with a wide array
        of resources and services.
      </h2>

      <div class="grid md:grid-cols-4 gap-4 text-left">
        <div class="image-title-description">
          <router-link to="/risk-management" aria-label="Risk Management">
            <img src="@/assets/riskmanagement-min.jpg" alt="Risk Management" />
          </router-link>
          <div class="fusion-text">
            <h2>Risk Management Experience</h2>
            <p>
              SMS is internationally recognized for providing a variety of risk
              management services to a wide range of commercial clients and
              government agencies. SMS is a specialized team of highly qualified
              engineers and scientists who have experience applying risk
              management protocol to ensure safe processing and handling of
              explosives and hazardous chemicals.
            </p>
          </div>
        </div>
        <div class="image-title-description">
          <router-link to="/testing" aria-label="Small Scale Burn Test">
            <img
              src="@/assets/testexperience-min.jpg"
              alt="Small Scale Burn Test"
            />
          </router-link>
          <div class="fusion-text">
            <h2>Testing Experience</h2>
            <p>
              SMS has served a wide variety of clients in testing energetic,
              flammable, and oxidizing substances and articles. We are one of
              six laboratories authorized by the DOT to recommend shipping
              classifications for Class 1 articles and substances. Five of the
              twelve authorized test agents work at SMS. Together they have over
              80 years of experience in testing propellants, pyrotechnics, and
              explosives.
            </p>
          </div>
        </div>
        <div class="image-title-description">
          <router-link to="/resources" aria-label="Engineering Resources">
            <img src="@/assets/engineering-min.jpg" alt="" />
          </router-link>
          <div class="fusion-text">
            <h2>Engineering Experience</h2>
            <p>
              Our engineering support team provides expertise throughout the
              entire manufacturing process. All of our team members are trained
              engineers with years of experience providing engineering support
              services to a broad range of clientele, ranging from aerospace to
              energetic material manufacturing companies in both private and
              public sectors. Our history and experience in working with
              manufacturing and testing provides our clients access to a unique
              set of skills enabling us to solve almost any problem your company
              may face.
            </p>
          </div>
        </div>
        <div class="image-title-description">
          <router-link to="/test-equipment" aria-label="testequipment">
            <img src="@/assets/testequipment-min.jpg" alt="testequipment" />
          </router-link>
          <div class="fusion-text">
            <h2>Test Equipment</h2>
            <p>
              The test equipment manufactured by Safety Management Services,
              Inc. is used internationally and throughout the United States. Our
              equipment has been purchased for sensitivity and reactivity
              testing in national laboratories, defense operations, and
              commercial enterprises.
            </p>
          </div>
        </div>
      </div>

      <div @mouseenter="cLinksBegin" @touchstart="cLinksBegin">
        <div class="sms-title py-10">
          <h2 class="title-heading">
            SMS Clients
          </h2>
          <div class="title-sep-container">
            <div class="title-sep sep-double sep-solid"></div>
          </div>
        </div>
        <div class="fusion-carousel">
          <div class="fusion-carousel-positioner" style="margin-left: -40px;">
            <div
              class="fusion-carousel-wrapper"
              style="display: block; text-align: start; float: none; position: relative; top: auto; right: auto; bottom: auto; left: auto; z-index: auto; width: 1160px; height: 115px; margin: 0px; overflow: hidden;"
            >
              <transition-group
                name="fusion-carousel"
                tag="ul"
                style="text-align: left; float: none; position: absolute; top: auto; right: auto; bottom: auto; left: 0px; margin: 0px; width: 6264px; height: 115px; line-height: 115px; z-index: auto;"
              >
                <li
                  class="fusion-carousel-item"
                  v-for="item in cLinks"
                  :key="item.id"
                  style="margin-left: 13px; width: 219px;"
                >
                  <div
                    class="fusion-carousel-item-wrapper"
                    style="visibility: inherit;"
                  >
                    <div class="fusion-image-wrapper hover-type-none">
                      <img
                        width="156"
                        height="113"
                        :src="require(`@/assets/Companies/${item.img}`)"
                        class="attachment-blog-medium size-blog-medium"
                        alt=""
                      />
                    </div>
                  </div>
                </li>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  metaInfo: {
    title: "SMS Home",
    titleTemplate: "%s | Safety Management Services, Inc.",
    meta: [
      {
        name: "description",
        content:
          "SMS is a leading consultant in the energetics industry. We provide services in process hazards analysis, testing and DOT shipping classification, D3 operations, and testing equipment."
      }
    ]
  },
  components: {},
  data: () => {
    return {
      hover: false,
      cLinksInterval: null,
      cLinks: [
        {
          img: "austinpowderlogoblack-min.png",
          id: 1
        },
        {
          img: "pacificscientificlogoblack-min.png",
          id: 2
        },
        {
          img: "trwlogoblack-min.png",
          id: 3
        },
        {
          img: "lockheedlogoblack-min.png",
          id: 4
        },
        {
          img: "vistaoutdoors-min.png",
          id: 5
        },
        {
          img: "nglogo-min.png",
          id: 6
        },
        {
          img: "armtecdef-min.png",
          id: 7
        },
        {
          img: "honeywelllogoblack-min.png",
          id: 8
        },
        {
          img: "aerojetlogoblack-min.png",
          id: 9
        },
        {
          img: "lendleaselogoblack-min.png",
          id: 10
        },
        {
          img: "poongsanlogoblack-min.png",
          id: 11
        }
      ]
    };
  },
  methods: {
    cLinksBegin() {
      if (!this.cLinksInterval) {
        this.cLinksInterval = setInterval(() => {
          let front = this.cLinks.shift();
          setTimeout(() => {
            this.cLinks.push(front);
          }, 1000);
        }, 2500);
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.cLinksInterval);
  }
};
</script>

<style lang="scss" scoped>
.fusion-carousel {
  overflow-x: hidden;
}
.fusion-carousel-item {
  transition: all 1s;
}
.fusion-carousel-enter, .fusion-carousel-leave-to
/* .fusion-carousel-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.fusion-carousel-leave-active {
  position: absolute;
}

.fusion-carousel .fusion-carousel-item {
  .attachment-blog-medium {
    max-height: 115px;
    width: auto;
    margin: auto;
  }
  display: block;
  float: left;
  padding: 0;
}

.image-title-description {
  .fusion-text {
    margin-top: 18px;
    h2 {
      font-size: 18px;
      color: #333;
      margin: 0 0 20px;
    }
    p {
      font-size: 13px;
    }
  }
}

.sms-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  .title-heading {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    margin: 0px;
    font-size: 18px;
    line-height: 1.5;
    font-family: Calibri, sans-serif;
    font-weight: 400;
    color: black;
    padding-right: 8px;
  }
  .title-sep-container {
    position: relative;
    height: 6px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    .title-sep {
      position: relative;
      display: block;
      border-style: solid;
      border: 0 solid #e7e6e6;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      border-color: #aa212c;
      height: 6px;
      border-bottom-width: 1px;
      border-top-width: 1px;
      width: 100%;
    }
  }
}

.sms-checklist {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
  line-height: 22.1px;
  font-size: 15px;
  margin-bottom: 20px;
  .li-item {
    text-align: left;
    padding: 5.5px 0;
    .circle-yes {
      display: table;
      float: left;
      border-radius: 50%;
      background-clip: padding-box;
      background-color: #333;
      color: #fff;
      text-align: center;
    }
  }
}

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}
.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3,
#carousel-4:checked ~ .control-4 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet,
#carousel-4:checked
  ~ .control-4
  ~ .carousel-indicators
  li:nth-child(4)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

@screen sm {
  .carousel-link {
    font-size: 1.25rem !important;
    top: 70% !important;
    left: 120px !important;
  }
}

.carousel-link {
  font-size: 1rem;
  position: absolute;
  top: 40%;
  left: 80px;
  background-color: #aa212c;
}
</style>
