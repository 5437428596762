export const headerData = [
 {
    name:'Home',
    route:'/'
 },
 {
    group:true,
    name:'Risk Management',
    route:'/risk-management',
    subGroups: [
       {
         group:true,
         name:'Process Hazards Analysis',
         route:'/process-hazards-analysis',
         subGroups: [
            {
               name:'FMEA Basis',
               route:'/process-hazards-analysis/fmea-basis'
            },
            {
               name:'HAZOP Basis',
               route:'/process-hazards-analysis/hazop-basis'
            },
         ]
       },
       {
         group:true,
         name:'Process Safety Management (PSM)',
         route:'/regulatory-compliance-audits/process-safety-management/',
         subGroups: [
            {
               name:'Regulatory Compliance Audits',
               route:'/regulatory-compliance-audits'
            },
            {
               name:'Explosive Safety Standards',
               route:'/explosive-safety-standards'
            },
            {
               name:'Mechanical Integrity',
               route:'/mechanical-integrity'
            },
            {
               name:'RAGAGEP',
               route:'/ragagep'
            }
         ]
       },
       {
         name:'Risk Management Programs (RMP)',
         route:'/regulatory-compliance-audits/risk-management-programs'
       },
       {
         name:'Layer of Protection Analysis (LOPA)',
         route:'/layer-of-protection-analysis'
       },
       {
         group: true,
         name:'Decommissioning, Decontamination, & Demolition (D3)',
         route:'/d3-operations',
         subGroups: [
            {
               name:'D3 Operation Experience',
               route:'/d3-operations/d3-experience'
            },
            {
               name:'Borescoping',
               route:'/d3-operations/borescoping'
            }
         ]
       },
       {
         group: true,
         name:'Facility Siting and Design',
         route:'/facility-siting-and-design',
         subGroups: [
            {
               name:'Hazards of Electromagnetic Radiation to Ordnance',
               route:'/facility-siting-and-design/rf-hazards'
            },
            {
               name:'Electrical Classification',
               route:'/facility-siting-and-design/electrical-classification'
            },
            {
               name:'Fire Suppression',
               route:'/facility-siting-and-design/fire-suppression'
            },
            {
               name:'Facility Siting and Design Experience',
               route:'/facility-siting-and-design/d3-experience'
            },
            {
               name:'Lightning Protection System (LPS) Support',
               route:'/facility-siting-and-design/lps'
            },
            {
               name:'Glass Hazards Analysis',
               route:'/facility-siting-and-design/gha'
            }
         ]
       },
       {
         name:'Environmental Services',
         route:'/environmental-services'
       },
       {
         name:'General Health and Safety',
         route:'/general-health-and-safety'
       },
       {
         group: true,
         subGroups: [
            {
               name:'IVM',
               route:'/modeling-and-analysis'
            },            {
               name:'BlastX Analysis',
               route:'/modeling-and-analysis/blastx-analysis'
            },
            {
               name:'Offsite Consequence Analysis',
               route:'/offsite-consequence-analysis'
            }
         ],
         name:'Modeling and Analysis',
         route:'/risk-management/modeling-and-analysis',
         skip: true
       },
       {
         name:'Incident Investigation',
         route:'/incident-investigation'
       },
       {
         name:'Electrostatic Discharge (ESD) Risk Evaluation',
         route:'/esd-risk-evaluation'
       },
       {
         name:'Combustible Dust',
         route:'/combustible-dust'
       },
       {
         name:'Arc Flash Analysis',
         route:'/arc-flash-hazards-analysis'
       },
       {
         name:'Chemical Facility Security',
         route:'/chemical-facility-security'
       }
    ]
 },
 {
   group:true,
   name:'Testing',
   route:'/testing',
   subGroups: [
       {
         group:true,
         name:'DOT/DOD Classification Testing',
         route:'/dottesting',
         subGroups: [
            {
               name:'UN/DOT Acceptance Procedure',
               route:'/dottesting/undot-acceptance-procedure'
            },
            {
               name:'Tests for Class 1 (Explosives)',
               route:'/dottesting/tests-for-class-1-explosives'
            },
            {
               name:'Tests for Class 4 (Flammable Solids)',
               route:'/dottesting/tests-for-class-4-flammable-solids'
            },
            {
               name:'Tests for Class 5 (Oxidizing Materials)',
               route:'/dottesting/tests-for-class-5-oxidizing-materials'
            },
         ]
      },
      {
         group: true,
         name:'In-Process Simulation Testing',
         route:'/in-process-simulation-testing',
         subGroups: [
            {
               name:'In-Process Tests',
               route:'/in-process-simulation-testing/in-process-tests'
            }            
         ]
      },
      {
         group:true,
         name:'Sensitivity Testing',
         route:'/sensitivity-testing',
         subGroups: [
            {
               name:'Material Sensitivity',
               route:'/sensitivity-testing/material-sensitivity'
            },
            {
               name:'PROBIT and TIL Analysis',
               route:'/sensitivity-testing/probit-and-til-analysis'
            },
            {
             name:'Thermal & Compatibility Tests',
             route:'/sensitivity-testing/thermal-and-compatibility-tests'
          },
          {
             name:'Electrical Properties',
             route:'/sensitivity-testing/electrical-properties'
          },
          {
             name:'Dust Explosibility',
             route:'/sensitivity-testing/dust-explosibility'
          },
          {
             name:'Large Scale Tests',
             route:'/sensitivity-testing/large-scale-tests'
          },
          {
             name:'Insensitive Munitions',
             route:'/sensitivity-testing/insensitive-munitions'
          },
          {
             name:'Reactivity',
             route:'/sensitivity-testing/reactivity'
          },
          {
             name:'Storage Classification Tests (BATF)',
             route:'/sensitivity-testing/storage-classification-tests-batf'
          },
          {
             name:'Sample Prepartion - Grinding',
             route:'/sensitivity-testing/grinding'
          }
         ]
       }, 
      {
         name:'Modeling and Analysis',
         route:'/risk-management/modeling-and-analysis',
         skip: true
       },      
      {
         name:'Test Site at Tooele Army',
         route:'/test-site-at-tead'
      },
      {
         name:'SMS Testing Assets',
         route:'/sms-testing-assets'
      },
      {
         name:'High-speed Video',
         route:'/high-speed-video'
      }
   ]
 },
 {
   group:true,
   name:'Test Equipment',
   route:'/test-equipment',
   subGroups: [
      {
         name:'SMS ABL Friction Machine',
         route:'/abl-friction'
      },
      {
         name:'SMS Tabletop ABL Friction Machine',
         route:'/abl-friction/abl-friction'
      },
      {
         name:'SMS MBOM Impact Machine',
         route:'/mbom-type-12-and-boe-impact'
      },
      {
         name:'SMS ABL Electrostatic Discharge Machine',
         route:'/abl-esd'
      },
      {
         name:'GoDetect™',
         route:'/godetect'
      },
      {
         name:'SMS SBAT Apparatus',
         route:'/sbat'
      },
      {
         skip:true,
         name:'Koenen Test Apparatus',
         route:'/koenen'
      },
      {
         name:'Time-Pressure Apparatus',
         route:'/time-pressure-apparatus'
      },
      {
         name:'SMS Heat Plate Apparatus',
         route:'/sms-heat-plate-apparatus'
      },
      {
         name:'Impingement Guns',
         route:'/impingement-guns'
      },
      {
         name:'DOT-SP Shipping Containers',
         route:'/shipping-containers'
      },
      {
         name:'Custom Shipping Containers / POP Testing',
         route:'/custom-shipping-containers'
      }
   ]
 },
 {
    group: true,
    name:'Training',
    route:'/training',
    subGroups: [
       {
          name:'Process Safety Management',
          route:'/process-safety-management-psm-principles-explosive-operations'
       },
       {
          name:'Explosives Classification Testing',
          route:'/dot-atf-process-classification-testing'
       },
       {
         name:'Process Hazards Analysis',
         route:'/process-hazards-analysis-pha-training-for-team-leaders'
      },
      {
         name:'Explosives Recognition & Decontamination',
         route:'/explosives-recognition-and-decontamination'
      },
      {
         name:'Fundamentals of Explosives Safety',
         route:'/fundamentals-of-explosives-safety'
      },
      {
         name:'Fundamentals of Explosives Testing',
         route:'/fundamentals-of-explosives-testing'
      },
      {
         name:'Fundamentals of a Combustible Dust Safety Program',
         route:'/fundamentals-of-a-combustible-dust-safety-program'
      },
      {
         name:'Electrical Classification',
         route:'/electrical-classification-hazards-protection-training'
      },
      {
         name:'Advanced Explosives Site Plan Training',
         route:'/advanced-explosives-site-plan-training'
      }
    ]
 },
 {
   group: true,
   name:'Resources',
   route:'/resources',
   flowLeft: true,
   subGroups: [
      {
         name:'DOT Links and Resources',
         route:'/dot-links-and-resources'
      },
      {
         name:'How to Obtain a Shipping Classification',
         route:'/obtain-a-shipping-classification'
      },
      {
         group: true,
         name:'Energetic Materials 101',
         route:'/energetic-materials-101',
         subGroups: [
            {
               name:'Explosives Safety Fundamentals',
               route:'/energetic-materials-101/explosive-safety-fundamentals'
            },
            {
               name:'Unplanned Migration of Energetics',
               route:'/unplanned-migration-of-energetics'
            }
         ]
      },
      {
         name:'Document Library',
         route:'/document-library'
      },
       {
         name:'Blog',
         route:'/blog'
      },
      {
         name:'ET Users Group',
         route:'/et-users-group'
      },
      {
         name:'Statistical Evaluation',
         route:'/statistical-evaluation'
      },
      {
         name:'SMS Team',
         route:'/sms-team'
      }
   ]
},
{
   name:'Contact Us',
   route:'/contact'
}
];