import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  //{ path: "/", component: Home },
  /*   { path: "/:base", component: Page },
  { path: "/:base/:sub", component: Page },
  { path: "/:base/:sub/:subsub", component: Page }, */
  //TODO determine which pages should be their own components, look into lazy loading, will it provide a benefit to lazy load the page component?
  {
    path: "/",
    name: "Home",
    component: Home
  },
  { path: "*", component: Home },
  {
    path: "/risk-management",
    name: "Risk Management",
    component: () =>
      import(
        /* webpackChunkName: "riskmanagement" */ "../views/RiskManagement/RiskManagement.vue"
      )
  },
  {
    path: "/risk-management/process-hazards-analysis",
    name: "ProcessHazardAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "processhazardanalysis" */ "../views/RiskManagement/ProcessHazardAnalysis.vue"
      )
  },
  {
    path: "/risk-management/process-hazards-analysis/fmea-basis",
    name: "ProcessHazardAnalysisFMEA",
    component: () =>
      import(/* webpackChunkName: "fmea" */ "../views/RiskManagement/FMEA.vue")
  },
  {
    path: "/risk-management/process-hazards-analysis/hazop-basis",
    name: "ProcessHazardAnalysisHAZOP",
    component: () =>
      import(
        /* webpackChunkName: "hazop" */ "../views/RiskManagement/HAZOP.vue"
      )
  },
  {
    path:
      "/risk-management/regulatory-compliance-audits/process-safety-management",
    name: "ProcessSafetyManagement",
    component: () =>
      import(
        /* webpackChunkName: "processsafetymanagement" */ "../views/RiskManagement/ProcessSafetyManagement.vue"
      )
  },
  {
    path: "/risk-management/ragagep",
    name: "RAGAGEP",
    component: () =>
      import(
        /* webpackChunkName: "RAGAGEP" */ "../views/RiskManagement/RAGAGEP.vue"
      )
  },
  {
    path: "/risk-management/mechanical-integrity",
    name: "MechanicalIntegrity",
    component: () =>
      import(
        /* webpackChunkName: "mechanicalintegrity" */ "../views/RiskManagement/MechanicalIntegrity.vue"
      )
  },
  {
    path: "/risk-management/explosive-safety-standards",
    name: "ExplosiveSafetyStandards",
    component: () =>
      import(
        /* webpackChunkName: "explosivesafetystandards" */ "../views/RiskManagement/ExplosiveSafetyStandards.vue"
      )
  },
  {
    path: "/risk-management/regulatory-compliance-audits",
    name: "RegulatoryComplianceAudits",
    component: () =>
      import(
        /* webpackChunkName: "regulatorycomplianceaudits" */ "../views/RiskManagement/RegulatoryComplianceAudits.vue"
      )
  },
  {
    path:
      "/risk-management/regulatory-compliance-audits/risk-management-programs", //TODO: metadata
    name: "Risk Management Programs",
    component: () =>
      import(
        /* webpackChunkName: "riskmanagementprograms" */ "../views/RiskManagement/RiskManagementPrograms.vue"
      )
  },
  {
    path: "/risk-management/layer-of-protection-analysis",
    name: "Layer of Protection Analysis",
    component: () =>
      import(/* webpackChunkName: "lopa" */ "../views/RiskManagement/LOPA.vue")
  },
  {
    path: "/risk-management/d3-operations",
    name: "D3 Operations",
    component: () =>
      import(
        /* webpackChunkName: "d3operations" */ "../views/RiskManagement/D3OperationalSupport.vue"
      )
  },
  {
    path: "/risk-management/d3-operations/d3-experience",
    name: "D3 Operation Experience",
    component: () =>
      import(
        /* webpackChunkName: "d3operationexperience" */ "../views/RiskManagement/D3OperationExperience.vue"
      )
  },
  {
    path: "/risk-management/d3-operations/borescoping",
    name: "Borescoping",
    component: () =>
      import(
        /* webpackChunkName: "borescoping" */ "../views/RiskManagement/Borescoping.vue"
      )
  },
  {
    path: "/risk-management/facility-siting-and-design",
    name: "FacilitySitingDesign",
    component: () =>
      import(
        /* webpackChunkName: "facilitysitingdesign" */ "../views/RiskManagement/FacilitySitingDesign.vue"
      )
  },
  {
    path: "/risk-management/facility-siting-and-design/rf-hazards",
    name: "HERO",
    component: () =>
      import(/* webpackChunkName: "hero" */ "../views/RiskManagement/HERO.vue")
  },
  {
    path:
      "/risk-management/facility-siting-and-design/electrical-classification",
    name: "ElectricalClassification",
    component: () =>
      import(
        /* webpackChunkName: "electricalclassification" */ "../views/RiskManagement/ElectricalClassification.vue"
      )
  },
  {
    path: "/risk-management/facility-siting-and-design/fire-suppression",
    name: "FireSuppression",
    component: () =>
      import(
        /* webpackChunkName: "firesuppression" */ "../views/RiskManagement/FireSuppression.vue"
      )
  },
  {
    path: "/risk-management/facility-siting-and-design/d3-experience/",
    name: "FSDE",
    component: () =>
      import(/* webpackChunkName: "fsde" */ "../views/RiskManagement/FSDE.vue")
  },
  {
    path: "/risk-management/facility-siting-and-design/lps",
    name: "LPSSupport",
    component: () =>
      import(
        /* webpackChunkName: "lpssupport" */ "../views/RiskManagement/LPSSupport.vue"
      )
  },
  {
    path: "/risk-management/facility-siting-and-design/gha",
    name: "Glass Hazards Analysis",
    component: () =>
      import(/* webpackChunkName: "gha" */ "../views/RiskManagement/GHA.vue")
  },
  {
    path: "/risk-management/general-health-and-safety",
    name: "GeneralHealthSafety",
    component: () =>
      import(
        /* webpackChunkName: "ghs" */ "../views/RiskManagement/GeneralHealthSafety.vue"
      )
  },
  {
    path: "/risk-management/environmental-services",
    name: "EnvironmentalServices",
    component: () =>
      import(
        /* webpackChunkName: "ghs" */ "../views/RiskManagement/EnvironmentalServices.vue"
      )
  },
  {
    path: "/risk-management/modeling-and-analysis",
    name: "ModelingAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "modelinganalysis" */ "../views/RiskManagement/ModelingAnalysis.vue"
      )
  },
  {
    path: "/risk-management/modeling-and-analysis/blastx-analysis",
    name: "BlastX",
    component: () =>
      import(
        /* webpackChunkName: "blastx" */ "../views/RiskManagement/BlastX.vue"
      )
  },
  {
    path: "/risk-management/offsite-consequence-analysis",
    name: "OffsiteConsequenceAnalysis",
    component: () =>
      import(
        /* webpackChunkName: "offsiteconsequenceanalysis" */ "../views/RiskManagement/OffsiteConsequenceAnalysis.vue"
      )
  },
  {
    path: "/risk-management/incident-investigation",
    name: "IncidentInvestigation",
    component: () =>
      import(
        /* webpackChunkName: "incidentinvestigation" */ "../views/RiskManagement/IncidentInvestigation.vue"
      )
  },
  {
    path: "/risk-management/esd-risk-evaluation",
    name: "ESD Risk Eval",
    component: () =>
      import(
        /* webpackChunkName: "esdriskeval" */ "../views/RiskManagement/ESDRiskEval.vue"
      )
  },
  {
    path: "/risk-management/combustible-dust",
    name: "Combustible Dust",
    component: () =>
      import(
        /* webpackChunkName: "combustibledust" */ "../views/RiskManagement/CombustibleDust.vue"
      )
  },
  {
    path: "/risk-management/arc-flash-hazards-analysis/",
    name: "Arc Flash Analysis",
    component: () =>
      import(
        /* webpackChunkName: "arcflashanalysis" */ "../views/RiskManagement/ArcFlashAnalysis.vue"
      )
  },
  {
    path: "/risk-management/chemical-facility-security",
    name: "ChemicalFacilitySecurity",
    component: () =>
      import(
        /* webpackChunkName: "chemicalsecurityfacility" */ "../views/RiskManagement/ChemicalSecurityFacility.vue"
      )
  },
  {
    path: "/testing",
    name: "Testing",
    component: () =>
      import(/* webpackChunkName: "testing" */ "../views/Testing/Testing.vue")
  },
  {
    path: "/testing/sensitivity-testing",
    name: "Sensitivity Testing",
    component: () =>
      import(
        /* webpackChunkName: "sensitivitytesting" */ "../views/Testing/SensitivityTesting.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/material-sensitivity",
    name: "Material Sensitivity",
    component: () =>
      import(
        /* webpackChunkName: "materialsensitivity" */ "../views/Testing/MaterialSensitivity.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/probit-and-til-analysis",
    name: "Probit Til Analysis",
    component: () =>
      import(
        /* webpackChunkName: "probittilanalysis" */ "../views/Testing/ProbitTIL.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/thermal-and-compatibility-tests",
    name: "Thermal and Compatibility Tests",
    component: () =>
      import(
        /* webpackChunkName: "thermalcompatibility" */ "../views/Testing/ThermalCompatibility.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/electrical-properties",
    name: "Electrical Properties",
    component: () =>
      import(
        /* webpackChunkName: "thermalcompatibility" */ "../views/Testing/ElectricalProperties.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/dust-explosibility",
    name: "Dust Explosibility",
    component: () =>
      import(
        /* webpackChunkName: "dustexplosibility" */ "../views/Testing/DustExplosibility.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/large-scale-tests",
    name: "Large Scale",
    component: () =>
      import(
        /* webpackChunkName: "largescale" */ "../views/Testing/LargeScaleTests.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/insensitive-munitions",
    name: "Insensitive Munitions",
    component: () =>
      import(
        /* webpackChunkName: "insensitivemunitions" */ "../views/Testing/InsensitiveMunitions.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/reactivity",
    name: "Reactivity",
    component: () =>
      import(
        /* webpackChunkName: "reactivitytesting" */ "../views/Testing/Reactivity.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/storage-classification-tests-batf",
    name: "Storage Classification",
    component: () =>
      import(
        /* webpackChunkName: "storageclassification" */ "../views/Testing/StorageClassification.vue"
      )
  },
  {
    path: "/testing/sensitivity-testing/grinding",
    name: "Sample Grinding",
    component: () =>
      import(
        /* webpackChunkName: "samplegrinding" */ "../views/Testing/SampleGrinding.vue"
      )
  },
  {
    path: "/testing/dottesting",
    name: "Dot Testing",
    component: () =>
      import(
        /* webpackChunkName: "dottesting" */ "../views/Testing/DotTesting.vue"
      )
  },
  {
    path: "/testing/dottesting/undot-acceptance-procedure",
    name: "Dot Acceptance procedure",
    component: () =>
      import(
        /* webpackChunkName: "dotacceptance" */ "../views/Testing/UnAcceptanceProcedure.vue"
      )
  },
  {
    path: "/testing/dottesting/tests-for-class-1-explosives",
    name: "Class 1 Explosives",
    component: () =>
      import(
        /* webpackChunkName: "class1explosives" */ "../views/Testing/Class1Explosives.vue"
      )
  },
  {
    path: "/testing/dottesting/tests-for-class-4-flammable-solids",
    name: "Class 4 Flammable Solids",
    component: () =>
      import(
        /* webpackChunkName: "class4flammablesolids" */ "../views/Testing/Class4FlammableSolids.vue"
      )
  },
  {
    path: "/testing/dottesting/tests-for-class-5-oxidizing-materials",
    name: "Class 5 Oxidizing Materials",
    component: () =>
      import(
        /* webpackChunkName: "class5oxidizingmaterials" */ "../views/Testing/Class5OxidizingMaterials.vue"
      )
  },
  {
    path: "/testing/in-process-simulation-testing",
    name: "In Process Simulation",
    component: () =>
      import(
        /* webpackChunkName: "inprocesssimulation" */ "../views/Testing/InProcessSimulation.vue"
      )
  },
  {
    path: "/testing/in-process-simulation-testing/in-process-tests",
    name: "In-Process Tests",
    component: () =>
      import(
        /* webpackChunkName: "inprocesstests" */ "../views/Testing/InProcessTests.vue"
      )
  },
  {
    path: "/testing/test-site-at-tead",
    name: "Tooele Army Depot",
    component: () =>
      import(
        /* webpackChunkName: "tooelearmydepot" */ "../views/Testing/TooeleArmyDepot.vue"
      )
  },
  {
    path: "/testing/sms-testing-assets",
    name: "SMS Testing Assets",
    component: () =>
      import(
        /* webpackChunkName: "smsassets" */ "../views/Testing/SMSAssets.vue"
      )
  },
  {
    path: "/testing/high-speed-video",
    name: "High Speed Video",
    component: () =>
      import(
        /* webpackChunkName: "highspeedvideo" */ "../views/Testing/HighSpeedVideo.vue"
      )
  },
  {
    path: "/test-equipment",
    name: "Test Equipment",
    component: () =>
      import(
        /* webpackChunkName: "testequipment" */ "../views/TestEquipment/TestEquipment.vue"
      )
  },
  {
    path: "/test-equipment/abl-friction",
    name: "ABL Friction",
    component: () =>
      import(
        /* webpackChunkName: "ablfriction" */ "../views/TestEquipment/ABLFriction.vue"
      )
  },
  {
    path: "/test-equipment/abl-friction/abl-friction",
    name: "ABL Friction Machine",
    component: () =>
      import(
        /* webpackChunkName: "ablfrictionmachine" */ "../views/TestEquipment/ABLFrictionMachine.vue"
      )
  },
  {
    path: "/test-equipment/mbom-type-12-and-boe-impact",
    name: "MBOM Impact Machine",
    component: () =>
      import(
        /* webpackChunkName: "mbomimpactmachine" */ "../views/TestEquipment/MBOMImpactMachine.vue"
      )
  },
  {
    path: "/test-equipment/abl-esd",
    name: "ElectrostaticDischargeMachine",
    component: () =>
      import(
        /* webpackChunkName: "electrostaticdischargemachine" */ "../views/TestEquipment/ElectrostaticDischargeMachine.vue"
      )
  },
  {
    path: "/test-equipment/godetect",
    name: "GoDetect",
    component: () =>
      import(
        /* webpackChunkName: "godetect" */ "../views/TestEquipment/GoDetect.vue"
      )
  },
  {
    path: "/test-equipment/sbat",
    name: "SBAT Apparatus",
    component: () =>
      import(
        /* webpackChunkName: "sbatapparatus" */ "../views/TestEquipment/SBATApparatus.vue"
      )
  },
  {
    path: "/koenen",
    name: "Koenen Test Apparatus",
    component: () =>
      import(
        /* webpackChunkName: "koenentest" */ "../views/TestEquipment/KoenenTest.vue"
      )
  },
  {
    path: "/test-equipment/time-pressure-apparatus",
    name: "Time Pressure Apparatus",
    component: () =>
      import(
        /* webpackChunkName: "timepressureapparatus" */ "../views/TestEquipment/TimePressureApparatus.vue"
      )
  },
  {
    path: "/test-equipment/sms-heat-plate-apparatus",
    name: "SMS Heat Plate Apparatus",
    component: () =>
      import(
        /* webpackChunkName: "heatplateapparatus" */ "../views/TestEquipment/HeatPlateApparatus.vue"
      )
  },
  {
    path: "/test-equipment/impingement-guns",
    name: "Impingement Guns",
    component: () =>
      import(
        /* webpackChunkName: "impingementguns" */ "../views/TestEquipment/ImpingementGuns.vue"
      )
  },
  {
    path: "/test-equipment/shipping-containers",
    name: "DOT-SP Shipping Containers",
    component: () =>
      import(
        /* webpackChunkName: "shippingcontainers" */ "../views/TestEquipment/ShippingContainers.vue"
      )
  },
  {
    path: "/test-equipment/custom-shipping-containers",
    name: "Custom Shipping Containers / POP Testing",
    component: () =>
      import(
        /* webpackChunkName: "customshippingcontainers" */ "../views/TestEquipment/CustomShippingContainers.vue"
      )
  },
  {
    path: "/training",
    name: "Training",
    component: () =>
      import(
        /* webpackChunkName: "training" */ "../views/Training/Training.vue"
      )
  },
  {
    path:
      "/training/process-safety-management-psm-principles-explosive-operations",
    name: "Process Safety Management",
    component: () =>
      import(
        /* webpackChunkName: "processsafetymanagement" */ "../views/Training/ProcessSafetyManagement.vue"
      )
  },
  {
    path: "/training/dot-atf-process-classification-testing",
    name: "Explosives Classfication Testing",
    component: () =>
      import(
        /* webpackChunkName: "processclassification" */ "../views/Training/ProcessClassificationTesting.vue"
      )
  },
  {
    path: "/training/process-hazards-analysis-pha-training-for-team-leaders",
    name: "PHA Training",
    component: () =>
      import(
        /* webpackChunkName: "phaTraining" */ "../views/Training/ProcessHazardsAnalysis.vue"
      )
  },
  {
    path: "/training/explosives-recognition-and-decontamination",
    name: "Explosives Recognition and Decontamination",
    component: () =>
      import(
        /* webpackChunkName: "explosivesrecognitiondecontamination" */ "../views/Training/ExplosivesRecognitionDecontamination.vue"
      )
  },
  {
    path: "/training/fundamentals-of-explosives-safety",
    name: "Fundamentals of Explosives Safety",
    component: () =>
      import(
        /* webpackChunkName: "fundamentalsexplosivesafety" */ "../views/Training/FundamentalsExplosiveSafety.vue"
      )
  },
  {
    path: "/training/fundamentals-of-explosives-testing",
    name: "Fundamentals of Explosives Testing",
    component: () =>
      import(
        /* webpackChunkName: "fundamentalsexplosivetesting" */ "../views/Training/FundamentalsExplosiveTesting.vue"
      )
  },
  {
    path: "/training/fundamentals-of-a-combustible-dust-safety-program",
    name: "Fundamentals of Combustible Dust Safety",
    component: () =>
      import(
        /* webpackChunkName: "fundamentalsdustsafety" */ "../views/Training/CombustibleDustSafety.vue"
      )
  },
  {
    path: "/training/electrical-classification-hazards-protection-training",
    name: "Electrical Classification",
    component: () =>
      import(
        /* webpackChunkName: "electricalclassification" */ "../views/Training/ElectricalClassificationHazardsProtection.vue"
      )
  },
  {
    path: "/training/advanced-explosives-site-plan-training",
    name: "Advanced Explosives Site Plan Training",
    component: () =>
      import(
        /* webpackChunkName: "advancedexplosivetesting" */ "../views/Training/AdvancedExplosivesSitePlan.vue"
      )
  },
  {
    path: "/resources",
    name: "Resources",
    component: () =>
      import(
        /* webpackChunkName: "resources" */ "../views/Resources/Resources.vue"
      )
  },
  {
    path: "/resources/dot-links-and-resources",
    name: "Dot Links Resources",
    component: () =>
      import(
        /* webpackChunkName: "dotlinksresources" */ "../views/Resources/DOTLinksResources.vue"
      )
  },
  {
    path: "/resources/obtain-a-shipping-classification",
    name: "Shipping Classification",
    component: () =>
      import(
        /* webpackChunkName: "shippingclassification" */ "../views/Resources/ShippingClassification.vue"
      )
  },
  {
    path: "/resources/energetic-materials-101",
    name: "Energetic Tasks 101",
    component: () =>
      import(
        /* webpackChunkName: "energeticmaterials101" */ "../views/Resources/EnergeticMaterials.vue"
      )
  },
  {
    path: "/resources/energetic-materials-101/explosive-safety-fundamentals",
    name: "Explosive Safety Fundamentals",
    component: () =>
      import(
        /* webpackChunkName: "explosivesafetyfundamentals" */ "../views/Resources/ExplosiveSafetyFundamentals.vue"
      )
  },
  {
    path: "/resources/unplanned-migration-of-energetics",
    name: "Unplanned Migration Energetics",
    component: () =>
      import(
        /* webpackChunkName: "unplannedmigrationenergetics" */ "../views/Resources/UnplannedMigration.vue"
      )
  },
  {
    path: "/resources/document-library",
    name: "Document Library",
    component: () =>
      import(
        /* webpackChunkName: "documentlibrary" */ "../views/Resources/DocumentLibrary.vue"
      )
  },
  {
    path: "/resources/blog",
    name: "Blog",
    component: () =>
      import(/* webpackChunkName: "blog" */ "../views/Resources/Blog.vue")
  },
  {
    path: "/conductive-plastic-tools",
    name: "Conducttive Plastic Tools",
    component: () =>
      import(
        /* webpackChunkName: "conductiveplastictools" */ "../views/Resources/Blog/ConductivePlasticTools.vue"
      )
  },
  {
    path: "/GoDetect8",
    name: "GoDetect Versions 8",
    component: () =>
      import(
        /* webpackChunkName: "GoDetect8" */ "../views/Resources/Blog/GoDetect8.vue"
      )
  },
  {
    path: "/mrp",
    name: "Mulwala Redevelopment Project",
    component: () =>
      import(/* webpackChunkName: "mrp" */ "../views/Resources/Blog/MRP.vue")
  },
  {
    path:
      "/fundamental-principles-of-explosives-safety-understanding-the-nature-of-explosives", //TODO: metadata
    name: "FPES",
    component: () =>
      import(/* webpackChunkName: "fpes" */ "../views/Resources/Blog/FPES.vue")
  },
  {
    path:
      "/fundamental-principles-of-explosives-safety-thorough-accurate-process-hazards-analysis-pha", //TODO: metadata
    name: "PHA",
    component: () =>
      import(/* webpackChunkName: "pha" */ "../views/Resources/Blog/PHA.vue")
  },
  {
    path: "/the-3-most-common-facility-siting-errors",
    name: "FSE",
    component: () =>
      import(/* webpackChunkName: "fse" */ "../views/Resources/Blog/FSE.vue")
  },
  {
    path: "/process-hazards-analysis-the-keystone-of-process-safety",
    name: "Keystone",
    component: () =>
      import(
        /* webpackChunkName: "keystone" */ "../views/Resources/Blog/Keystone.vue"
      )
  },
  {
    path: "/resources/technical-presentations",
    name: "Technical Presentations",
    component: () =>
      import(
        /* webpackChunkName: "technicalpresentations" */ "../views/Resources/TechnicalPresentations.vue"
      )
  },
  {
    path: "/resources/et-users-group",
    name: "ET Users Group",
    component: () =>
      import(
        /* webpackChunkName: "etusersgroup" */ "../views/Resources/ETUsersGroup.vue"
      )
  },
  {
    path: "/resources/statistical-evaluation",
    name: "Statistical Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "statisticalevaluation" */ "../views/Resources/StatisticalEvaluation.vue"
      )
  },
  {
    path: "/resources/sms-team",
    name: "SMS Team",
    component: () =>
      import(/* webpackChunkName: "smsteam" */ "../views/Resources/SMSTeam.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Resources/Contact.vue")
  },
  {
    path: "/lo-ohm",
    name: "Lo-ohm",
    component: () =>
      import(/* webpackChunkName: "lo-ohm" */ "../views/Loohm.vue")
  },
  {
    path: "/shop",
    name: "Store",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/Store.vue")
  },
  {
    path: "/shop/:category/",
    name: "Store Category",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/Store.vue")
  },
  {
    path: "/shop/:category/:product",
    name: "Store Product",
    component: () =>
      import(/* webpackChunkName: "store" */ "../views/Store.vue")
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import(/* webpackChunkName: "cart" */ "../views/Cart.vue")
  },
  {
    path: "/ergonomic-analysis",
    name: "Ergonomic Analysis",
    component: () =>
      import(
        /* webpackChunkName: "ergonomicanalysis" */ "../views/RiskManagement/ErgonomicAnalysis.vue"
      )
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
